/*
 * @Author: duanyunlong
 * @since: 2020-07-22 22:05:22
 * @lastTime: 2020-07-24 20:54:41
 * @LastAuthor: Do not edit
 * @FilePath: \dc_container\src\main.js
 * @message: vue main文件
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
// 主要以iview组件库为主，饿了么按需引入（切记）
import { Carousel, CarouselItem, Scrollbar, Tree, Image, DatePicker, Button, Rate, Table, TableColumn } from 'element-ui'
// 导入全局指令
import directive from './directive/index'
// 导入全局过滤器
import filters from './filters/index'
import SlideVerify from 'vue-monoplasty-slide-verify'
// 安装iview
Vue.use(ViewUI)
// 使用全局指令
Vue.use(directive)
// 使用全局指令
Vue.use(filters)
// 安装饿了么走马灯等
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Scrollbar)
Vue.use(Tree)
Vue.use(Image)
Vue.use(DatePicker)
Vue.use(Button)
Vue.use(Rate)
Vue.use(Table)
Vue.use(TableColumn)
// 安装SlideVerify组件
Vue.use(SlideVerify)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
